import { useState } from 'react';
import { Link } from 'react-router-dom';
import './NameHeaderMobile.scss';
import { APP_ROUTES } from 'utils/routing/routing';
import { useMode } from 'contexts/ModeContext';

interface NameHeaderMobileProps {
  toggleFlag: boolean;
  isMixDifference: boolean;
}

const NameHeaderMobile: React.FC<NameHeaderMobileProps> = ({ toggleFlag, isMixDifference }) => {

  const { mode, toggleMode } = useMode();
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);

    setTimeout(() => {
      setIsClicked(false);
    }, 750); // Adjust the duration as needed
  };

  return (
    <div className='nameheader-mobile-main-container'>
      <Link
        to={APP_ROUTES.HOME}
        className={`Link 
       ${isMixDifference ? 'mix-difference' : mode === 'blue' ? 'blue' : 'dark'}
       ${isClicked ? 'clicked' : ''}
       nameheader-mobile-link-container`}
        onClick={() => { toggleMode(toggleFlag); handleClick();}}
      >
        <div className='iacopoguscetti'>
          <div className='iacopo-mobile'>   iacopo    </div>
          <div className='guscetti-mobile'> guscetti  </div>
        </div>
      </Link>
    </div>
  );
}

export default NameHeaderMobile;