import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import NameHeaderMobile from 'mobile/NameHeaderMobile/NameHeaderMobile';
import LowerLeftMobile from 'mobile/LowerLeftMobile/LowerLeftMobile';
import { useCloudBackground } from 'contexts/CloudBackgroundContext';
import './WorksList.scss';
import WorksData from "pages/Works/WorksData.json";

import { useMode } from 'contexts/ModeContext';

const WorksList = () => {
  
  const { cloudBackground, hideCloudBackground } = useCloudBackground();
  const navigate = useNavigate();
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 || window.innerHeight < 768
  );
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const handleImageLoad = () => {
    const allImagesLoaded = WorksData.every((project) => project.imageCoverMobile);
    if (allImagesLoaded) {
      setImagesLoaded(true);
    }
  };

  useEffect(() => {

    hideCloudBackground(true);

    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      setIsMobile(window.innerWidth < 768 || window.innerHeight < 768);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {

    // console.log('windowSize[0]: ', windowSize[0]);

    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      // TODO make it sensible of orientation!!!!
      setIsMobile(window.innerWidth < 768 || window.innerHeight < 768);
    };

    window.addEventListener('resize', handleWindowResize);

  }, [windowSize[0]]);

  useEffect(() => {
    if (!isMobile) {
      navigate('/');
    }
  }, [isMobile, navigate]);

  return (
    <div className='workslist-main-container'>

      <div className={`
        ${imagesLoaded ? '' : 'hide'}
        workslist-main-div-mobile`}>

        {WorksData.map((project) => {
          return (
            <div key={project.id} className='div-map-mobile-cover-picture' >

              <Link to={project.link} className='Link'>
                <img
                  src={project.imageCoverMobile}
                  alt=""
                  className='workslist-cover-mobile'
                  onLoad={handleImageLoad} />
              </Link>

              <Link to={project.link} className='Link'>
                <div className='workslist-cover-title'>

                  {project.title}

                </div>
              </Link>

              

            </div>
          );
        })}
      </div>

      <NameHeaderMobile toggleFlag={false} isMixDifference={true} />

      <LowerLeftMobile isHome={false} />

    </div>
  );
}

export default WorksList;