import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CloudBackground } from 'models';
import './App.css'
import { BrowserRouter } from 'react-router-dom';
import { ModeProvider } from 'contexts/ModeContext';
import { CloudBackgroundProvider } from 'contexts/CloudBackgroundContext';
import { ModeRefreshProvider } from 'contexts/RefreshVantaContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const mode = "blue";

root.render(


  <React.StrictMode>
    <ModeProvider>
      <ModeRefreshProvider>
      <CloudBackgroundProvider >
        <CloudBackground />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CloudBackgroundProvider>
      </ModeRefreshProvider>
    </ModeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
