import React, { createContext, useContext, useState, ReactNode } from 'react';

interface RefreshContextProps {
  refresh: boolean;
  toggleModeRefresh: (toggleValue: boolean) => void; // Corrected function name
}

const RefreshContext = createContext<RefreshContextProps | undefined>(undefined);

export const ModeRefreshProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [refresh, setRefresh] = useState<boolean>(false);

  const toggleModeRefresh = (toggleValue: boolean) => {
    setRefresh(toggleValue);
  };

  return (
    <RefreshContext.Provider value={{ refresh, toggleModeRefresh }}>
      {children}
    </RefreshContext.Provider>
  );
};

export const useRefresh = () => {
  const context = useContext(RefreshContext);
  if (!context) {
    throw new Error('useMode must be used within a ModeProvider');
  }
  return context;
};
