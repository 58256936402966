import { useState } from "react";
import { Link } from "react-router-dom";
import "./NameHeaderProject.scss"
import { APP_ROUTES } from "utils/routing/routing";

const NameHeaderProject = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="name-header-project-main-container">
      <Link
        to={APP_ROUTES.HOME}
        className={`Link ${isHovered ? 'name-project-offset name-header-both-hovered' : 'name-project-offset name-header-both-nothovered'}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>

        <div className="iacopo">
          iacopo
        </div>

        <div className="guscetti">
          guscetti
        </div>

      </Link>
    </div>
  );
}

export default NameHeaderProject;