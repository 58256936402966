import { Link } from "react-router-dom";
import { useMode } from "contexts/ModeContext";
import './LinksList.scss';
import '../../../App.css'

interface LinksListProps {
  linksList: { id: number, title: string, link: string, iconBlue?: string, iconDark?: string }[];
}

const LinksList: React.FC<LinksListProps> = ({ linksList }) => {
  const { mode } = useMode();

  return (
    <>

      <div className="desktop-container">

        {linksList.map(link => (
          link.title === 'CV' ? (
            <a
              key={link.id}
              href={link.link}
              download="Iacopo_Guscetti_CV.pdf"
              className={`Link menu-project-element-about ${mode === 'blue' ? 'blue-about' : 'dark-about'
                }`}
            >
              <div
                className={`menu-project-element-about ${mode === 'blue' ? 'blue-about' : 'dark-about'
                  }`}
              >
                {link.title}
              </div>
            </a>
          ) : (
            <Link
              key={link.id}
              to={link.link}
              target="_blank"
              className={`Link menu-project-element-about ${mode === 'blue' ? 'blue-about' : 'dark-about'
                }`}
            >
              <div
                className={`menu-project-element-about ${mode === 'blue' ? 'blue-about' : 'dark-about'
                  }`}
              >
                {link.title}
              </div>
            </Link>
          )
        ))}

      </div>

      <div className="mobile-container">

        <div className="about-mobile-links">
          {linksList.map(link => (

            <div key={link.id} className="link-individual-mobile">
              <Link
                to={link.link}
                target="_blank"
                className={`Link ${mode === 'blue' ? 'blue-about' : 'dark-about'
                  }`}
              >
                <img src={mode === 'blue' ? link.iconBlue : link.iconDark} alt="" style={{ height: '25px' }} />
              </Link>
            </div>

          ))}
        </div>

      </div>

    </>
  );
};

export default LinksList;