import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMode } from 'contexts/ModeContext';
import './LowerRightButton.scss'

interface LowerRightButtonProps {
  text: string;
  link: string;
  showLowerRight: boolean;
  showLowerRightUp: boolean;
  onProjectHover: number | null;
}

const LowerRightButton: React.FC<LowerRightButtonProps> = ({ text, link, showLowerRight, showLowerRightUp, onProjectHover }) => {

  const { mode } = useMode();

  return (
    <>
      <div className='desktop-container about-offset'>
        <Link
          to={link}
          className={
            `Link about-lower-right
          ${onProjectHover === null ? (mode === 'blue' ? 'blue' : 'dark') : 'about-diff'}
        ${showLowerRight === true && showLowerRightUp === true ? '' : 'hide'}
        `}>
          {text}
        </Link>
      </div>


      <div className='mobile-section'>
        <div className='aboutmobile-lower-right-offset' id='amlr'>
          <Link
            id='almo'
            to={link}
            className={
              `Link aboutmobile-lower-right
          ${mode === 'blue' ? 'blue' : 'dark'}`}>
            {text}
          </Link>
        </div>
      </div>
    </>
  );
};

export default LowerRightButton;

// ${mode === 'blue' ? 'blue' : 'dark'}