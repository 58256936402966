import './App.css';
import { NameLayout } from './models';
import { About, Home } from './pages';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'utils/routing/routing';
import { ProjectDetail } from './models';
import { WorksList } from 'mobile';
import { useMode } from 'contexts/ModeContext';
import WorksData from "pages/Works/WorksData.json";

function App() {
  const { mode } = useMode();

  const theyBlossomProject = WorksData.find((project) => project.id === 1);
  const gemsProject = WorksData.find((project) => project.id === 2);
  const carnyxProject = WorksData.find((project) => project.id === 3);
  const carnyxPerformance = WorksData.find((project) => project.id === 4);

  const navigate = useNavigate();

  const redirectToHomePage = () => {
    navigate(APP_ROUTES.HOME, { replace: true });
  };

  return (

    <div className={`mobile-vh-test ${mode === 'blue' ? 'custom-cursor-blue' : 'custom-cursor-dark'}`}>
      <NameLayout>

        <div className={`App ${mode === 'blue' ? 'custom-cursor-blue' : 'custom-cursor-dark'}`}>

          <Routes>

            <Route
              path={APP_ROUTES.HOME}
              element={<Home />} />

            <Route
              path={APP_ROUTES.ABOUT}
              element={<About />} />

            <Route
              path={APP_ROUTES.THEYBLOSSOM}
              element={theyBlossomProject ?
                <ProjectDetail key={theyBlossomProject.id} project={theyBlossomProject} /> : <div></div>} />

            <Route
              path={APP_ROUTES.GEMS}
              element={gemsProject ?
                <ProjectDetail key={gemsProject.id} project={gemsProject} /> : <div></div>} />

            <Route
              path={APP_ROUTES.CARNYX}
              element={carnyxProject ?
                <ProjectDetail key={carnyxProject.id} project={carnyxProject} /> : <div></div>} />

            <Route
              path={APP_ROUTES.CARNYX_PERFORMANCE}
              element={carnyxPerformance ?
                <ProjectDetail key={carnyxPerformance.id} project={carnyxPerformance} /> : <div></div>} />

            <Route
              path={APP_ROUTES.WORKS_MOBILE}
              element={<WorksList />} />

            <Route path="*" element={<Home />} />



          </Routes>

        </div>

      </NameLayout>
    </div>
  );
}

export default App;