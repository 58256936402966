import './PictureMain.scss';

interface PictureMainProps {
  isClicked: boolean;
  currentIndex: number;
  images: string[];
  handleImageLoaded: () => void;
}

const PictureMain: React.FC<PictureMainProps> = ({ isClicked, currentIndex, images, handleImageLoaded }) => {

  return (
    <div className="centered-image " >
      <img
        className={!isClicked ? 'image-no-blur-project' : 'image-no-blur-project'}
        src={images[currentIndex]} alt="" 
        onLoad={() => handleImageLoaded()}/>
    </div >
  );
};

export default PictureMain;