import './NavigationButton.scss';

interface NavigationButtonProps {
  side: string;
  handleNavigationButton: (side: string) => void;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({ side, handleNavigationButton }) => {

  return (
    <button
      className={`button ${side === 'left' ? 'left' : 'right'}`}
      onClick={() => { handleNavigationButton(side); }}>
    </button>
  );
};

export default NavigationButton;