import { Link } from 'react-router-dom';
import './LowerLeftMobile.scss'
import { APP_ROUTES } from 'utils/routing/routing';
import { useMode } from 'contexts/ModeContext';

interface LowerLeftMobileProps {
  isHome: boolean;
}

const LowerLeftMobile:React.FC<LowerLeftMobileProps> = ({ isHome }) => {

  const { mode } = useMode();

  return (
    <div className={`lowerleft-mobile-main-container ${isHome ? 'llm-home' : 'llm-nohome'}`}>
      <Link
        to={APP_ROUTES.WORKS_MOBILE}
        className={`Link 
        ${isHome ? (mode === 'blue' ? 'blue' : 'dark') : 'llm-nohome'}
        lowerleft-mobile-link-container`}
      >
        works
      </Link>
    </div>
  );
}

export default LowerLeftMobile;