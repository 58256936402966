import React, { useEffect } from 'react';

interface NameLayoutProps {
    children: React.ReactNode;
}

const NameLayout = ({children}: NameLayoutProps) => {
    useEffect(() => {
    document.title = "iacopo guscetti";
    }, []);

    return <>{children}</>;
}

export default NameLayout;