import { useState, useEffect } from "react";
import { NameHeader, LowerRightButton } from "models";
import { NameHeaderMobile } from "mobile";
import TextUpperRight from "models/MenuHeaderProject/elements/TextUpperRight/TextUpperRight";
import LinksList from "./LinksList/LinksList";
import "./About.scss"
import { APP_ROUTES } from "utils/routing/routing";
import { useMode } from "contexts/ModeContext";


const About = () => {

  const marginLeft = 10;
  const marginTop = 40;
  const marginTopMobile = 20;
  const { mode } = useMode();
  const [shouldShowBottomLeft, setShouldShowBottomLeft] = useState(true);
  const [showLowerRight, setShowLowerRight] = useState(false);
  const [showLowerRightUp, setShowLowerRightUp] = useState(false);
  const [showUpperRight, setShowUpperRight] = useState(false);
  const [showLowerRightMobile, setShowLowerRightMobile] = useState(false);
  const [showLowerLeftMobile, setShowLowerLeftMobile] = useState(false);
  const [maxElements, setMaxElements] = useState(0);
  const [nameHeaderPosition, setNameHeaderPosition] = useState<number | undefined>(undefined);
  const [lowerRightPositionLeft, setLowerRightPositionLeft] = useState<number | undefined>(undefined);
  const [lowerRightPositionRight, setLowerRightPositionRight] = useState<number | undefined>(undefined);
  const [aboutHeight, setAboutHeight] = useState<number | undefined>(undefined);
  const [aboutBodyHeight, setAboutBodyHeight] = useState<number | undefined>(undefined);
  const [mainTextWidth, setMainTextWidth] = useState<number | undefined>(undefined);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const text = `
  <span>
  <div class="sentence-spacing-desktop"> 
  Iacopo Guscetti (CH, 1989) operates at the convergence of creative coding and sound design.
  His aural sensitivity, coupled with programming skills, gives rise to interactive and immersive sound design experiences.
  In his work, he establishes a distinctive connection between the listener and the surrounding sound environment, experimenting with technology to question our relationship to space and sound.
  </div>

  <div>
  Past exhibitions: <a class='text-link' href='https://www.gaite-lyrique.net/' target='_blank' >La Gaîté Lyrique</a>, <a class='text-link' href='https://mcah.ch/expositions/expositions-passees/sacre-mormont/' target='_blank' > MCAH Lausanne</a>, <a class='text-link' href='https://ecal.ch/fr/vie-etudiante/campus/equipements-ateliers/exposition-des-diplomes-2021-espace-arlaud-lausanne/' target='_blank' > Espace Arlaud</a>.
  </div>
  </span>`;

  const textMobile = `
  <span>
  <div class="sentence-spacing"> 
  Iacopo Guscetti (CH, 1989) operates at the convergence of creative coding and sound design.
  <br>His aural sensitivity, coupled with programming skills, gives rise to interactive and immersive sound design experiences.
  <br>In his work, he establishes a distinctive connection between the listener and the surrounding sound environment, experimenting with technology to question our relationship to space and sound.
  </div>

  <div>
  Past exhibitions: <a class='text-link' href='https://www.gaite-lyrique.net/' target='_blank' >La Gaîté Lyrique</a>, <a class='text-link' href='https://mcah.ch/expositions/expositions-passees/sacre-mormont/' target='_blank' >MCAH Lausanne</a>, <a class='text-link' href='https://ecal.ch/fr/vie-etudiante/campus/equipements-ateliers/exposition-des-diplomes-2021-espace-arlaud-lausanne/' target='_blank' >Espace Arlaud</a>.
  </div>
  </span>`;

  const linksList = [
    { id: 0, title: 'CV', link: '/Iacopo_Guscetti_CV.pdf' },
    { id: 1, title: 'EMAIL', link: 'mailto:iacopo.guscetti@ik.me' },
    { id: 2, title: 'GITHUB', link: 'https://github.com/iguscett' },
    { id: 3, title: 'LINKEDIN', link: 'https://www.linkedin.com/in/iacopo-guscetti/' },
    { id: 4, title: 'INSTAGRAM', link: 'https://www.instagram.com/iac_ppyg/' },
    { id: 5, title: 'SOUNDCLOUD', link: 'https://soundcloud.com/eren_gx' }
  ];

  const linksListMobile = [
    { id: 0, title: 'EMAIL', link: 'mailto:iacopo.guscetti@ik.me', iconBlue: '/mail_blue.png', iconDark: '/mail_dark.png' },
    { id: 1, title: 'GITHUB', link: 'https://github.com/iguscett', iconBlue: '/github_blue.png', iconDark: '/github_dark.png' },
    { id: 2, title: 'LINKEDIN', link: 'https://www.linkedin.com/in/iacopo-guscetti/', iconBlue: '/linkedin_blue.png', iconDark: '/linkedin_dark.png' },
    { id: 3, title: 'INSTAGRAM', link: 'https://www.instagram.com/iac_ppyg/', iconBlue: '/insta_blue.png', iconDark: '/insta_dark.png' },
    { id: 4, title: 'SOUNDCLOUD', link: 'https://soundcloud.com/eren_gx', iconBlue: '/scloud_blue.png', iconDark: '/scloud_dark.png' }
  ];

  const updateLowerLeft = () => {
    const upperLeftElement = document.querySelector('.name-header-both') as HTMLElement;
    const lowerLeftElement = document.querySelector('.about-secondary-container') as HTMLElement;

    if (upperLeftElement && lowerLeftElement) {
      const distance = lowerLeftElement.offsetTop - (upperLeftElement.offsetTop + upperLeftElement.offsetHeight);
      const cond1 = distance > 10;
      setShouldShowBottomLeft(cond1);
    }
  };

  const updateLowerRightRight = () => {
    const lowerLeftList = document.querySelector('.about-menu-offsettop') as HTMLElement;
    const lowerRight = document.querySelector('.about-offset') as HTMLElement;

    if (lowerLeftList && lowerRight)
      setShowLowerRight(lowerRight.getBoundingClientRect().left - lowerLeftList.getBoundingClientRect().right > margin);
  };

  const updateLowerRightUp = () => {
    const upperRight = document.querySelector('.project-text-up-about') as HTMLElement;
    const lowerRight = document.querySelector('.about-offset') as HTMLElement;



    if (upperRight && lowerRight) {
      const distance = lowerRight.getBoundingClientRect().top - 40 - upperRight.getBoundingClientRect().bottom;
      setShowLowerRightUp(distance > margin);
    }
    // console.log("distance: " + distance);
    // console.log("showLowerRightUp: " + showLowerRightUp);
  };

  useEffect(() => {
    setMaxElements(linksList.length);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // DESKTOP MARGINS
  useEffect(() => {
    const nameHeader = document.querySelector('.name-header-both') as HTMLElement;
    const lowerRight = document.querySelector('.about-lower-right') as HTMLElement;
    const aboutBody = document.querySelector('.about-body') as HTMLElement;
    setNameHeaderPosition(nameHeader.getBoundingClientRect().right + marginLeft);
    setLowerRightPositionLeft(lowerRight.getBoundingClientRect().left + marginLeft);
    setLowerRightPositionRight(lowerRight.getBoundingClientRect().right);
    setAboutHeight(lowerRight.getBoundingClientRect().bottom - marginTop);
  }, []);

  // DESKTOP DYNAMIC MARGINS
  useEffect(() => {
    const lowerRight = document.querySelector('.about-lower-right') as HTMLElement;
    setLowerRightPositionLeft(lowerRight.getBoundingClientRect().left + marginLeft);
    setLowerRightPositionRight(lowerRight.getBoundingClientRect().right);
    setAboutHeight(lowerRight.getBoundingClientRect().bottom - marginTop);
  }, [windowSize[0]]);

  const margin = 5;

  useEffect(() => {
    if (lowerRightPositionRight === undefined || nameHeaderPosition === undefined) return;
    setMainTextWidth(lowerRightPositionRight - nameHeaderPosition);
  }, [lowerRightPositionRight, nameHeaderPosition]);

  useEffect(() => {

    updateLowerLeft();
    updateLowerRightRight();
    updateLowerRightUp();

  }, [windowSize[0], windowSize[1]]);

  return (
    <div className="global-container-about">

      <div className="desktop-container">

        <NameHeader onProjectHover={null} toggleFlag={false} />

        <TextUpperRight
          isAbout={true}
          text={text}
          textColor=""
          isClicked={true}
          currentIndex={0}
          showText={true} />

        <div className="about-secondary-container" style={{ minHeight: shouldShowBottomLeft ? 'auto' : `${maxElements * 50.5}px` }}>

          {shouldShowBottomLeft &&
            <div className="about-menu-offsettop">
              <LinksList linksList={linksList} />
            </div>}
        </div>

        <LowerRightButton text={`home`} link={APP_ROUTES.HOME} showLowerRight={showLowerRight} showLowerRightUp={showLowerRightUp} onProjectHover={null} />
      </div>

      <div className="mobile-container">

        <NameHeaderMobile toggleFlag={false} isMixDifference={false} />

        <TextUpperRight
          isAbout={true}
          text={textMobile}
          textColor=""
          isClicked={true}
          currentIndex={0}
          showText={true} />

        <LowerRightButton text={'home'} link={APP_ROUTES.HOME} showLowerRight={showLowerRight} showLowerRightUp={showLowerRightUp} onProjectHover={null} />

        <LinksList linksList={linksListMobile} />

      </div>

    </div >
  );
};

export default About;