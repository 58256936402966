import React, { useState, useEffect, useRef } from 'react'
// import CLOUDS from 'vanta/dist/vanta.topology.min'
// @ts-ignore
import CLOUDS from 'vanta/dist/vanta.fog.min'
// import CLOUDS from 'vanta/src/vanta.topology'
import './CloudBackground.css'
// Make sure window.THREE is defined, e.g. by including three.min.js in the document head using a <script> tag
import { useMode } from 'contexts/ModeContext';
import { useRefresh } from 'contexts/RefreshVantaContext';
import { useCloudBackground } from 'contexts/CloudBackgroundContext';
import '../../App.css'


const CloudBackground = React.memo((props: any) => {
  const [vantaEffect, setVantaEffect] = useState<any>(null)
  const vantaRef = useRef(null)
  const { mode, toggleMode } = useMode();
  const { refresh, toggleModeRefresh } = useRefresh();
  const { cloudBackground } = useCloudBackground();
  const [cloudMode, setCloudMode] = useState('blue');

  useEffect(() => {

    // console.log('Effect triggered with mode:', mode);

    if (!vantaEffect) {
      // console.log('INSIDE VANTA');
      setVantaEffect(CLOUDS({
        el: vantaRef.current,

        // BLUE DEEP
        highlightColor: mode === "blue" ? 0x99bbd8 : 0x233646,
        midtoneColor: mode === "blue" ? 0x61758e : 0x464843,
        lowlightColor: mode === "blue" ? 0x1f3c58 : 0x807466,
        baseColor: mode === "blue" ? 0xe8e8e8 : 0x0f0b0a,

        // PINK
        // highlightColor: 0xe2d7cf,
        // midtoneColor: 0xc38181,
        // lowlightColor: 0xa04e4f,
        // baseColor: 0xffffff,

        // DARK
        // highlightColor: 0x233646,
        // midtoneColor: 0x464843,
        // lowlightColor: 0x807466,
        // baseColor: 0x0f0b0a,

        blurFactor: 0.77,
        zoom: 0.7,

        // gyroControls: false,
        // mouseControls: false,
        // touchControls: false,
        // speed: 0.2,
        // skyColor: 0xffffff,
        // backgroundColor: 0xffffff,

      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect, mode])

  const updateVantaEffect = () => {
    if (vantaEffect) vantaEffect.destroy();

    setVantaEffect(
      CLOUDS({
        el: vantaRef.current,
        highlightColor: mode === 'blue' ? 0x99bbd8 : 0x233646,
        midtoneColor: mode === 'blue' ? 0x61758e : 0x464843,
        lowlightColor: mode === 'blue' ? 0x1f3c58 : 0x807466,
        baseColor: mode === 'blue' ? 0xe8e8e8 : 0x0f0b0a,
        blurFactor: 0.77,
        zoom: 0.7,
      })
    );
  };

  useEffect(() => {
    updateVantaEffect();
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [mode]);

  useEffect(() => {
    if (refresh) {
      updateVantaEffect();
      toggleModeRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    setCloudMode(mode);
  },[]);

  useEffect(() => {
    setCloudMode(mode);
  },[mode]);

  return (
    <div className={` ${cloudBackground === 'hide' ? 'hide' : cloudMode === 'blue' ? 'custom-cursor-blue' : 'custom-cursor-dark'}`} >
      <div className='vanta' ref={vantaRef}>
      </div>
    </div>
  );
});

export default CloudBackground;
// 1
// highlightColor: 0xffffff,
//   midtoneColor: 0x9b9be8,
//     lowlightColor: 0x2d00ff,
//       baseColor: 0xd6d7e1,