import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./MenuHeaderProject.css"
import NavigationButton from "./elements/NavigationButton/NavigationButton";
import PictureMain from "./elements/PictureMain/PictureMain";
import TextUpperRight from "./elements/TextUpperRight/TextUpperRight";
import LowerRight from "./elements/LowerRight/LowerRight";
import WorksData from "pages/Works/WorksData.json";
import ProjectsList from "./elements/ProjectsList/ProjectsList";
import Overlay from "./elements/Overlay/Overlay";

interface MenuHeaderProjectProps {
  setOnProjectHover: (projectID: number | null) => void;
  id: number;
  images: string[];
  images_overlay: string[];
  images_overlay_size: string[];
  text: string[];
  textColor: string[];
  music: string[];
}

const MenuHeaderProject: React.FC<MenuHeaderProjectProps> = ({ setOnProjectHover, id, images, images_overlay, images_overlay_size, text, textColor, music }) => {

  const [shouldShowBottomLeft, setShouldShowBottomLeft] = useState(true);
  const [shouldShowBottomRightHeight, setShouldShowBottomRightHeight] = useState(true);
  const [shouldShowBottomRightWidth, setShouldShowBottomRightWidth] = useState(true);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [maxElements, setMaxElements] = useState(0);
  const [idHovered, setIdHovered] = useState<number | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isClicked, setIsClicked] = useState(false);
  const [path, setPath] = useState("");
  const maxIndex = images.length - 1;
  const musicSize = music.length;
  const navigate = useNavigate();
  const [isMutedNew, setIsMutedNew] = useState(true);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [onNavigation, setOnNavigation] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(true);

  useEffect(() => {

    let video = document.getElementById("video") as HTMLVideoElement;
    if (video)
      video.volume = 0;
    setMaxElements(WorksData.length);

  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleProjectHover = (projectID: number | null) => {
    setOnProjectHover(projectID);
    setIdHovered(projectID);
  };

  useEffect(() => {

    const updateMaxHeight = () => {
      const upperLeftElement = document.querySelector('.name-project-offset') as HTMLElement;
      const lowerLeftElement = document.querySelector('.project-secondary-container') as HTMLElement;

      if (upperLeftElement && lowerLeftElement) {
        const distance = lowerLeftElement.offsetTop - (upperLeftElement.offsetTop + upperLeftElement.offsetHeight);
        setShouldShowBottomLeft(distance > 10);
      }

      const upperRightElement = document.querySelector('.project-text-up') as HTMLElement;
      const lowerRightElement = document.querySelector('.project-music-player-offset') as HTMLElement;

      if (upperRightElement && lowerRightElement) {
        const distance = lowerRightElement.offsetTop - (upperRightElement.offsetTop + upperRightElement.offsetHeight);

        setShouldShowBottomRightHeight(distance > 20);
      }
    };

    const updateMaxWidth = () => {
      const lowerLeftElement = document.querySelector('.project-secondary-container') as HTMLElement;
      const lowerRightElement = document.querySelector('.project-music-player-offset') as HTMLElement;

      if (lowerLeftElement && lowerRightElement) {
        const distance = lowerRightElement.offsetLeft - (lowerLeftElement.getBoundingClientRect().right);
        // console.log("distance 22: " + distance);
        // console.log("lowerRightElement.offsetLeft: " + lowerRightElement.offsetLeft + "   lowerLeftelement height: " + lowerLeftElement.getBoundingClientRect().right);

        setShouldShowBottomRightWidth(distance > 20);
      }
    };

    updateMaxHeight();
    updateMaxWidth();

  }, [windowSize[0], windowSize[1]]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === 'ArrowLeft') {
        
        setCurrentIndex((prevIndex) => prevIndex - 1 < 0 ? maxIndex : prevIndex - 1);
        setIsVideoLoaded(false);
        setOnNavigation((prevOnNavigation) => !prevOnNavigation);
        setShowIcon(false);

      } else if (e.key === 'ArrowRight') {
        setCurrentIndex((prevIndex) => (prevIndex + 1 >= images.length ? 0 : prevIndex + 1));
        setIsVideoLoaded(false);
        setShowIcon(false);
        setOnNavigation((prevOnNavigation) => !prevOnNavigation);

      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [images.length]);

  useEffect(() => {
    // Add a class to the centered-image element to trigger the ease-out blur effect
    const centeredImageElement = document.querySelector('.image-no-blur-project');
    // image-no-blur-project
    if (centeredImageElement) {
      centeredImageElement.classList.remove('ease-out-blur');
    }
  }, []);

  useEffect(() => {

    const preloadImage = () => {
      const project = WorksData.find((proj) => proj.id === idHovered);
      if (project && project.images && project.images.length > 0) {
        const firstImageUrl = project.images[0];
        const image = new Image();
        image.src = firstImageUrl;
      }
    };

    // Redirect to project page when isClicked is true
    if (isClicked && idHovered !== null) {
      preloadImage();
      const delayDuration = 500;
      setTimeout(() => {
        setCurrentIndex(0);
        setIsClicked(false);
        navigate(path);

      }, delayDuration);
    }
    // setOnNavigation(!onNavigation);

  }, [isClicked, idHovered, path]);

  useEffect(() => {
    const handleImageLoaded = () => {
      setImageLoaded(false);
    };

    if (currentIndex !== 0)
    setImageLoaded(false);
  }, [currentIndex]);

  const handleProjectClick = (projectLink: string) => {
    const video = document.getElementById("video") as HTMLVideoElement;

    if (video) {
      video.volume = 0;
    }
    setIsMutedNew(true);

    setIsClicked(true);
    setPath(projectLink);
    setOnNavigation(!onNavigation);
  };

  const handleNavigationButton = (direction: string) => {
    if (direction === 'left') {
      setCurrentIndex((prevIndex) => prevIndex - 1 < 0 ? maxIndex : prevIndex - 1);
      setIsVideoLoaded(false);
      setOnNavigation(!onNavigation);
      setShowIcon(false);
    } else if (direction === 'right') {
      setCurrentIndex((prevIndex) => (prevIndex + 1 >= images.length ? 0 : prevIndex + 1));
      setIsVideoLoaded(false);
      setShowIcon(false);
      setOnNavigation(!onNavigation);
    }
  }

  const handleImageLoaded = () => {
    setImageLoaded(true);
  }

  return (
    <div className="project-global-container">

      {/* IMPORTANT CHANGE THE VALUE 50.5 WHENEVER ADJUSTING THE SPACING OR FONT SIZE */}
      <div className="project-secondary-container" style={{ minHeight: shouldShowBottomLeft ? 'auto' : `${maxElements * 50.5}px` }}>

        <div className="project-menu-offsettop">

          {/* Navigation buttons */}
          <NavigationButton side="left" handleNavigationButton={handleNavigationButton} />
          <NavigationButton side="right" handleNavigationButton={handleNavigationButton} />

          {/* Picture main */}
          <PictureMain
            isClicked={isClicked}
            currentIndex={currentIndex}
            images={images}
            handleImageLoaded={handleImageLoaded} />

          {/* Image overlay */}
          <Overlay
            key={'new'}
            images_overlay={images_overlay}
            images_overlay_size={images_overlay_size}
            currentIndex={currentIndex}
            isVideoLoaded={isVideoLoaded}
            showIcon={showIcon}
            isMutedNew={isMutedNew}
            onNavigation={onNavigation}
            setIsVideoLoaded={setIsVideoLoaded}
            setShowIcon={setShowIcon}
            setIsMutedNew={setIsMutedNew} />

          {/* Text Up */}
          <TextUpperRight
          isAbout={false}
          text={text[currentIndex]}
          textColor={textColor[currentIndex]}
          isClicked={isClicked}
          currentIndex={currentIndex}
          showText={imageLoaded} />

          {/* Music player / Lower right */}
          <LowerRight musicSize={musicSize} shouldShowBottomRightHeight={shouldShowBottomRightHeight} shouldShowBottomRightWidth={shouldShowBottomRightWidth} music={music[currentIndex]} />

          {/* Projects list */}
          <ProjectsList id={id} idHovered={idHovered} shouldShowBottomLeft={shouldShowBottomLeft} handleProjectClick={handleProjectClick} handleProjectHover={handleProjectHover} />

        </div>
      </div>
    </div>
  );
};

export default MenuHeaderProject;


