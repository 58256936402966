import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./MenuHeader.css"
import { APP_ROUTES } from "utils/routing/routing";
import { useMode } from "contexts/ModeContext";
import WorksData from "pages/Works/WorksData.json";

interface HoveredState {
  prev: number | null;
  current: number | null;
}

interface MenuHeaderProps {
  setOnProjectHover: (projectID: number | null) => void;
}

const MenuHeader: React.FC<MenuHeaderProps> = ({ setOnProjectHover }) => {

  const { mode } = useMode();
  const [shouldShowBottom, setShouldShowBottom] = useState(true);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [maxElements, setMaxElements] = useState(0);
  const [idHovered, setIdHovered] = useState<number | null>(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [preloadedImages, setPreloadedImages] = useState<HTMLImageElement[]>([]);
  const [path, setPath] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setMaxElements(WorksData.length);
  }, []);

  useEffect(() => {
    // Preload all images when the component mounts
    const preloadImages = WorksData.map((project) => {
      const img = new Image();
      img.src = project.imageCover;
      return img;
    });
    setPreloadedImages(preloadImages);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleProjectHover = (projectID: number | null, isClicked: boolean) => {
    if (isClicked) return;
    setOnProjectHover(projectID);
    setIdHovered(projectID);
  };

  const handleProjectClick = (projectLink: string) => {
    setIsClicked(true);
    setPath(projectLink)
  };

  useEffect(() => {

    const preloadImage = () => {
      const project = WorksData.find((proj) => proj.id === idHovered);
      if (project && project.images && project.images.length > 0) {
        const firstImageUrl = project.images[0];
        const image = new Image();
        image.src = firstImageUrl;
      }
    };

    // Redirect to project page when isClicked is true
    if (isClicked && idHovered !== null) {
      preloadImage();
      const delayDuration = 500;
      setTimeout(() => {
        navigate(path);
      }, delayDuration);
    }

  }, [isClicked, idHovered, path]);

  useEffect(() => {

    const updateMaxHeight = () => {
      const upperElement = document.querySelector('.name-header-both') as HTMLElement;;
      const lowerElement = document.querySelector('.menu-list-container') as HTMLElement;;

      if (upperElement && lowerElement) {
        const distance = lowerElement.offsetTop - (upperElement.offsetTop + upperElement.offsetHeight);
        setShouldShowBottom(distance > 10);
      }
    };

    updateMaxHeight();

  }, [windowSize[1]]);

  useEffect(() => {
    WorksData.map((project) => {
      const image = new Image();
      image.src = project.imageCover;
    });
  }, []);

  useEffect(() => {

  }, [idHovered]);

  return (
    <div className="header-main-container">

      {/* IMPORTANT CHANGE THE VALUE 50.5 WHENEVER ADJUSTING THE SPACING OR FONT SIZE */}
      <div className="menu-list-container" style={{ minHeight: shouldShowBottom ? 'auto' : `${maxElements * 50.5}px` }}>

        {shouldShowBottom ? (
          <div className="menu-header-offsettop">

            {/* Picture */}
            {/* {idHovered != null && WorksData && WorksData.length > 0 && (
              <div className='centered-image' key={idHovered}>
                <img
                  className='image-no-blur appear'
                  src={WorksData.find((project) => project.id === idHovered)?.imageCover || ''} alt="" />
              </div>
            )} */}
            {
              WorksData.map((project, index) => {
                if (idHovered === project.id) {
                  return (
                    <div className='centered-image' key={index}>
                      <img
                        className='image-no-blur appear'
                        src={project.imageCover}
                        alt={`Project ${index}`}
                      />
                    </div>
                  );
                }
                return null; // Don't forget to handle the case when idHovered doesn't match project.id
              })
            }

            {/* Description */}
            {idHovered != null && WorksData && WorksData.length > 0 && (
              <div className="project-description-short">
                {WorksData.find((project) => project.id === idHovered)?.description}
              </div>
            )}

            {/* Projects list */}

            {WorksData.map(project => (
              <div key={project.id}>
                <Link
                  className='Link'
                  to=''
                  style={{ textDecoration: 'none' }}
                  onClick={() => handleProjectClick(project.link)}>
                  <div
                    className={`
                  ${mode === 'blue' ? 'blue' : 'dark'}
                  ${idHovered === null ? 'menu-header-element' : idHovered === project.id ? 'menu-header-element-hovered' : 'menu-header-element-nothovered'}`}
                    key={project.id}
                    onMouseEnter={() => handleProjectHover(project.id, isClicked)}
                    onMouseLeave={() => handleProjectHover(null, isClicked)}>

                    {project.title}

                  </div>
                </Link>
              </div>
            ))}


          </div>) : null}

      </div>

    </div>
  );
};

export default MenuHeader;