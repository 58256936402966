import './LowerRight.scss';

interface LowerRightProps {
  musicSize: number;
  shouldShowBottomRightHeight: boolean;
  shouldShowBottomRightWidth: boolean;
  music: string;
}


const LowerRight: React.FC<LowerRightProps> = ({ musicSize, shouldShowBottomRightHeight, shouldShowBottomRightWidth, music }) => {

  return (
    <div className="project-music-player-offset">
      {

        musicSize > 0 &&

        <div
          id='audio-player'
          className={`project-music-player
          ${shouldShowBottomRightHeight && shouldShowBottomRightWidth ? "" : "player-no-opacity"}`}>
          
          <audio controls
            controlsList="nodownload noplaybackrate" >
           
            <source src={music} type="audio/mpeg" />
          
          </audio>
        
        </div>

      }
    </div>
  );
};

export default LowerRight;