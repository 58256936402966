import { useRef, useState, useEffect } from 'react';
import './Overlay.scss';

interface OverlayProps {
  images_overlay: string[];
  images_overlay_size: string[];
  currentIndex: number;
  isVideoLoaded: boolean;
  showIcon: boolean;
  isMutedNew: boolean;
  onNavigation: boolean;

  setIsVideoLoaded: (isVideoLoaded: boolean) => void;
  setShowIcon: (showIcon: boolean) => void;
  setIsMutedNew: (isMutedNew: boolean) => void;
}

const Overlay: React.FC<OverlayProps> = ({ images_overlay, isMutedNew, showIcon, images_overlay_size, onNavigation, currentIndex, isVideoLoaded, setIsVideoLoaded, setShowIcon, setIsMutedNew }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showVideo, setShowVideo] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [skeletonWidth, setSkeletonWidth] = useState(0);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
    if (videoRef.current) {
      const { videoWidth, videoHeight } = videoRef.current;
      setDimensions({ width: videoWidth, height: videoHeight });
    }
  };

  useEffect(() => {

    setTimeout(() => {
      setShowVideo(true);
      setIsVideoLoaded(true);
    }, 300)
  }, [])

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const updateDimensions = () => {
      if (videoRef.current) {
        const { videoWidth, videoHeight } = videoRef.current;
        setDimensions({ width: videoWidth, height: videoHeight });
      }
    };

    const video = videoRef.current;
    if (video){
      video.addEventListener('loadeddata', handleVideoLoad);
      video.addEventListener('loadeddata', updateDimensions);
    }
    return () => {
      if (video) {
        video.removeEventListener('loadeddata', handleVideoLoad);
        video.removeEventListener('loadeddata', updateDimensions);
      }
    };
  }, [videoRef])

  useEffect(() => {
    
    const ratio = dimensions.width / dimensions.height;
    const height = parseInt(images_overlay_size[currentIndex]) * 0.01 * windowSize[1];
 
    setSkeletonWidth(height * ratio);
  },[dimensions]);

  useEffect(() => {
    setTimeout(() => {
      setShowVideo(true);
      setIsVideoLoaded(true);
    }, 300)
  }, [onNavigation])

  return (
    <>
      {
        images_overlay[currentIndex] !== "" &&
        images_overlay.length !== 0 &&
        (
          <div
            className={`Link centered-imageoverlay-project
            ${images_overlay[currentIndex] === "" || images_overlay.length === 0 || !showVideo || !isVideoLoaded ? 'hide' : 'show'}`}
            style={{
              top: `${(100 - parseInt(images_overlay_size[currentIndex])) / 2}%`,
              height: `${images_overlay_size[currentIndex]}vh`,
              width: `${skeletonWidth}px`,
            }}>

            <video
              id="video"
              ref={videoRef}
              src={images_overlay[currentIndex]}
              style={{
                height: '100%',
                position: 'relative',
                zIndex: 2,
              }}
              autoPlay
              loop
              controls
              disablePictureInPicture
              controlsList='nodownload noplaybackrate'>
            </video>

          </div>)}
    </>
  );
};

export default Overlay;