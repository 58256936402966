import { Link } from 'react-router-dom';
import './LowerRightMobile.scss'
import { APP_ROUTES } from 'utils/routing/routing';
import { useMode } from 'contexts/ModeContext';

const LowerRightMobile = () => {

  const { mode } = useMode();

  return (
    <div className='lowerright-mobile-main-container'>
      <Link
        to={APP_ROUTES.ABOUT}
        className={`Link 
        ${mode === 'blue' ? 'blue' : 'dark'}
        lowerright-mobile-link-container`}
      >
        about
      </Link>
    </div>
  );
}

export default LowerRightMobile;