import { Link } from "react-router-dom";
import './ProjectsList.scss';
import WorksData from "pages/Works/WorksData.json";

interface ProjectsListProps {
  id: number;
  idHovered: number | null;
  shouldShowBottomLeft: boolean;

  handleProjectClick: (link: string) => void;
  handleProjectHover: (id: number | null) => void;
}

const ProjectsList: React.FC<ProjectsListProps> = ({ id, idHovered, shouldShowBottomLeft, handleProjectClick, handleProjectHover }) => {

  return (
    <>
      {shouldShowBottomLeft ? (
        WorksData.map(project => (
          <Link
            onClick={() => {handleProjectClick(project.link);}}
            className={`Link ${id === project.id ? 'menu-project-element-hovered heavier' : idHovered === project.id ? 'menu-project-element-hovered-main' : 'menu-project-element'}`}
            to=''
            key={project.id}>

            <div
              className={`Link ${id === project.id ? 'menu-project-element-hovered' : idHovered === project.id ? 'menu-project-element-hovered' : 'menu-project-element'}`}
              key={project.id}
              onMouseEnter={() => handleProjectHover(project.id)}
              onMouseLeave={() => handleProjectHover(null)}>
                
              {project.title}

            </div>

          </Link>

        ))) : null}

    </>
  );
};

export default ProjectsList;