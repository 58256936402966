import { useState, useEffect } from "react";
import "./Home.css"
import { MenuHeader, NameHeader, LowerRightButton } from "models";
import { useCloudBackground } from "contexts/CloudBackgroundContext";
import { APP_ROUTES } from 'utils/routing/routing';
import { LowerLeftMobile, LowerRightMobile, NameHeaderMobile } from "mobile";
import { useLocation, useNavigate } from "react-router-dom";
import { useRefresh } from "contexts/RefreshVantaContext";

const Home = () => {

  const { cloudBackground, hideCloudBackground } = useCloudBackground();
  const [onProjectHover, setOnProjectHover] = useState<null | number>(null);
  const { refresh, toggleModeRefresh } = useRefresh();
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [showLowerRight, setShowLowerRight] = useState(false);
  const margin = 1;

  const location = useLocation();
  const navigate = useNavigate();

  const updateMaxWidth = () => {
    const lowerLeftList1 = document.querySelector('.project-menu-offsettop') as HTMLElement;
    const lowerLeftList2 = document.querySelector('.menu-header-offsettop') as HTMLElement;
    const lowerRight = document.querySelector('.about-offset') as HTMLElement;

    if (lowerLeftList1 && lowerRight)
      setShowLowerRight(lowerRight.getBoundingClientRect().left - lowerLeftList1.getBoundingClientRect().right > margin);
    else if (lowerLeftList2 && lowerRight) {
      setShowLowerRight(lowerRight.getBoundingClientRect().left - lowerLeftList2.getBoundingClientRect().right > margin);
      const value1 = lowerRight.getBoundingClientRect().left;
      const value2 = lowerLeftList2.getBoundingClientRect().right;
    }
  }

  useEffect(() => {
    if (location.pathname !== APP_ROUTES.HOME) {
      navigate(APP_ROUTES.HOME, { replace: true });
    }
  }, [location]);

  useEffect(() => {
    hideCloudBackground(false);
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    toggleModeRefresh(true);
    
    window.addEventListener('resize', handleWindowResize);
    updateMaxWidth();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    updateMaxWidth();
  }, [windowSize[0]]);

  return (
    <>
      <div className="desktop-container global-container">

        <NameHeader onProjectHover={onProjectHover} toggleFlag={true} />

        <div className="z-index-max-home">
          <MenuHeader setOnProjectHover={setOnProjectHover} />
        </div>

        <div className={`${onProjectHover !== null ? 'lower-right-diff' : ''}`}>
          <LowerRightButton text={'about'} link={APP_ROUTES.ABOUT} showLowerRight={showLowerRight} showLowerRightUp={true} onProjectHover={onProjectHover} />
        </div>

      </div>

      <div className="mobile-container global-container">

        <NameHeaderMobile toggleFlag={true} isMixDifference={false} />

        <LowerLeftMobile isHome={true} />

        <LowerRightMobile />

      </div>
    </>
  );
};

export default Home;
