import { Link } from "react-router-dom";
import "./NameHeader.scss"
import { APP_ROUTES } from "utils/routing/routing";
import { useMode } from "contexts/ModeContext";

interface NameHeaderProps {
  onProjectHover: null | number;
  toggleFlag: boolean;
}

const NameHeader: React.FC<NameHeaderProps> = ({ onProjectHover, toggleFlag }) => {
  
  const {mode, toggleMode} = useMode();

  return (
    <div className="name-header-main-container">
      <Link
        to={APP_ROUTES.HOME}
        className={`Link
        ${mode === "blue" ? "blue" : "dark"}
        ${onProjectHover === null ? "name-header-both" : "name-header-both dif"}`}
        onClick={() => toggleMode(toggleFlag)}
        >


        <div className="iacopo">
          iacopo
        </div>

        <div className="guscetti">
          guscetti
        </div>

      </Link>
    </div>
  );
}

export default NameHeader;