import { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import './TextUpperRight.scss'
import { useMode } from 'contexts/ModeContext';

interface TextUpperRightProps {
  text: string;
  isClicked: boolean;
  currentIndex: number;
  isAbout: boolean;
  textColor: string;
  showText: boolean;
}

const TextUpperRight: React.FC<TextUpperRightProps> = ({ text, textColor, isClicked, currentIndex, isAbout, showText }) => {
  const allowedTags = ['b', 'a', 'span', 'br', 'div'];
  const allowedAttributes = ['href', 'target', 'style', 'className', 'class'];
  const [sanitizedText, setSanitizedText] = useState<string>(DOMPurify.sanitize(text, { ALLOWED_TAGS: allowedTags, ALLOWED_ATTR: allowedAttributes }));
  const { mode } = useMode();

  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  const textDiv = document.querySelector('.project-text-up') as HTMLElement;

  useEffect(() => {
    setSanitizedText(DOMPurify.sanitize(text, { ALLOWED_TAGS: allowedTags, ALLOWED_ATTR: allowedAttributes }));
  }, [currentIndex, isClicked]);

  useEffect(() => {
    if (isSafari() && textDiv) {
      // If Safari, remove the backdrop filter property
      textDiv.style.backdropFilter = 'none';
    } else if (textDiv) {
      // If not Safari, apply the backdrop filter property
      // console.log('not safari OKKKKKK');
      textDiv.style.backdropFilter = 'blur(1.5px)';
    }
  }, [isSafari(), textDiv]);

  return (
    <div>
      {/* DESKTOP */}
      {!isAbout && showText && (
        <>
          <div
            className={`${textColor === '' ? 'project-text-up project-text-up-dif' : 'project-text-up'}`}
            style={{ color: textColor === '' ? '' : textColor }}
            dangerouslySetInnerHTML={{ __html: sanitizedText }}>
          </div>
        </>
      )}
      {isAbout && (
        <>
          {/* DESKTOP */}
          <div
            className={`desktop-container project-text-up-about ${mode === 'blue' ? 'blue' : 'dark'}`}
            dangerouslySetInnerHTML={{ __html: sanitizedText }}>
          </div>

          {/* MOBILE */}
          <div id='mata'
            className={`mobile-section mobile-text-about
          ${mode === 'blue' ? 'blue' : 'dark'}
          mobile-about-text-font-size`}
            dangerouslySetInnerHTML={{ __html: sanitizedText }}>
          </div>
        </>
      )}
    </div>
  );
};

export default TextUpperRight;