import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { MenuHeaderProject, NameHeaderProject } from "models";
import { NameHeaderMobile, LowerLeftMobile } from 'mobile';
import { useCloudBackground } from 'contexts/CloudBackgroundContext';
import { APP_ROUTES } from 'utils/routing/routing';
import './ProjectDetail.scss';

interface ProjectDetailProps {
  project: {
    id: number;
    title: string;
    description: string;
    tags: string[];
    imageCover: string;
    imageCoverMobile: string;
    images: string[];
    imagesMobile: string[];
    images_overlay: string[];
    images_overlay_size: string[];
    poster: string[];
    text: string[];
    textColor: string[];
    textMobile: string[];
    textMobileBackgroundColor: string[];
    textMobileColor: string[];
    music: string[];
    link: string;
  };
}

const ProjectDetail: React.FC<ProjectDetailProps> = ({ project }) => {
  const { id, title, description, tags, imageCover, imageCoverMobile, images, imagesMobile, images_overlay, images_overlay_size, poster, text, textColor, textMobile, textMobileBackgroundColor, textMobileColor, music, link } = project;
  const { cloudBackground, hideCloudBackground } = useCloudBackground();
  const [onProjectHover, setOnProjectHover] = useState<null | number>(null);
  const [overlaySize, setOverlaySize] = useState<number>(0);
  const [hasMusic, setHasMusic] = useState<boolean>(music.length > 0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [unfold, setUnfold] = useState<boolean>(false);
  const [showPlayer, setShowPlayer] = useState<boolean>(false);
  const videoRefs = useRef<HTMLVideoElement[]>([]);
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const allowedTags = ['b', 'a', 'span', 'br', 'div'];
  const allowedAttributes = ['href', 'target', 'style', 'className', 'class'];
  const [sanitizedTextMobile, setSanitizedTextMobile] = useState<string[]>([]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {

    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // TODO
  useEffect(() => {
    if (windowSize[0] < 768 || windowSize[1] < 768) {
      hideCloudBackground(true);
    }
    else {
      hideCloudBackground(false);
    }
  }, [windowSize]);

  useEffect(() => {
    const purifiedTextMobile = textMobile.map((text) =>
      DOMPurify.sanitize(text, { ALLOWED_TAGS: allowedTags, ALLOWED_ATTR: allowedAttributes })
    );
    setSanitizedTextMobile(purifiedTextMobile);
  }, []);

  const pauseOtherVideos = (currentVideoId: string) => {
    videoRefs.current.forEach((videoRef) => {
      if (videoRef && videoRef.id !== currentVideoId) {
        videoRef.pause();
      }
    });
  };

  useEffect(() => {
    document.title = document.title + " - " + title.toLowerCase();

    if (images_overlay.length > 0)
      setOverlaySize(1);

    return () => {
      document.title = "iacopo guscetti";
    }
  }, []);

  useEffect(() => {

    const preloadImages = () => {
      images.forEach((image, index) => {
        const imageToLoad = new Image();
        imageToLoad.src = image;
      });

    };

    preloadImages();

  }, []);

  const handleCircleClick = () => {
    setShowPlayer(true);
    if (!isPlaying) {
      const audioElement = document.getElementById('audio-mobile-element') as HTMLAudioElement;
      if (audioElement && audioElement.paused)
        audioElement.play();
    }
  };

  useEffect(() => {

    if (showPlayer) {
      timerRef.current = setTimeout(() => {
        setShowPlayer(false)
      }, 4000);
    }

  }, [showPlayer])

  const handlePlayPauseClick = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setShowPlayer(false);
    }, 4000);
  };

  return (
    <div className="global-container">

      {/* DESKTOP */}
      <div className='desktop-container'>
        <div className="z-index-max-name"> <NameHeaderProject /> </div>

        <div className="z-index-max">
          <MenuHeaderProject
            setOnProjectHover={setOnProjectHover}
            id={id}
            images={images}
            images_overlay={images_overlay}
            images_overlay_size={images_overlay_size}
            text={text}
            textColor={textColor}
            music={music} />
        </div>

      </div>


      {/* MOBILE */}
      <div className='mobile-container'>

        <div className='project-detail-mobile-main'>

          <div className='first-cover-photo'>

            <img src={imageCoverMobile} alt="" className='first-cover-photo-img' />

            <div className='project-title-mobile'>
              {title.toUpperCase()}
            </div>

          </div>

          <div className='texts-images-map-mobile'>

            {imagesMobile.map((image, index) => {
              return (
                <div key={index} className='div-map-mobile'
                  style={{ backgroundColor: textMobileBackgroundColor[index] }}
                  >

                  {sanitizedTextMobile[index] !== '' &&
                  <div
                    className='text-map-mobile'
                    style={{ backgroundColor: textMobileBackgroundColor[index], color: `${textMobileColor[index]}` }}
                    dangerouslySetInnerHTML={{ __html: sanitizedTextMobile[index] }}
                    >
                  </div>}

                  <div className={`${overlaySize === 0 ? 'img-map-mobile-container' : 'hide'}`}>
                    <img
                      src={image} alt=""
                      className={`img-map-mobile ${overlaySize === 0 ? '' : 'hide'}`} />
                  </div>

                  <video controls
                    className={`${overlaySize === 0 ? 'hide' : 'video-overlay-element'}`}
                    poster={poster[index]}
                    controlsList="nodownload"
                    ref={(video) => (videoRefs.current[index] = video as HTMLVideoElement)}
                    id={index.toString()}
                    onPlay={() => pauseOtherVideos(index.toString())}
                  >
                    <source src={images_overlay[index]} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>

                </div>
              );
            })}

          </div>

          <NameHeaderMobile toggleFlag={false} isMixDifference={true} />

          <LowerLeftMobile isHome={false} />

          <div
            className={`audio-mobile-container ${hasMusic ? '' : 'hide'}`}>

            <button
              id="circle-button"
              className='circle-button'
              style={{ display: showPlayer ? 'none' : 'block' }}
              onClick={handleCircleClick}>
              {isPlaying ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="#fb5803"
                  stroke="#fb5803"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-pause"
                  style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <rect x="6" y="4" width="4" height="16"></rect>
                  <rect x="14" y="4" width="4" height="16"></rect>
                </svg>) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="#fb5803"
                  stroke="#fb5803"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-play"
                  style={{ position: 'absolute', top: '50%', left: '54%', transform: 'translate(-50%, -50%)' }}>
                  <polygon points="5 3 19 12 5 21 5 3"></polygon>
                </svg>
              )}

            </button>

            <audio controls
              controlsList='nodownload noplaybackrate'
              id='audio-mobile-element'
              className='audio-mobile-element'
              style={{ display: showPlayer ? 'block' : 'none', width: '250px' }}
              onPlay={() => { setIsPlaying(true); handlePlayPauseClick() }}
              onPause={() => { setIsPlaying(false); handlePlayPauseClick() }}
              onClick={handlePlayPauseClick}
              onDrag={handlePlayPauseClick}
              onDragEnter={handlePlayPauseClick}
              onDragCapture={handlePlayPauseClick}
              onDragEnterCapture={handlePlayPauseClick}
              onDragLeave={handlePlayPauseClick}
              onDragOverCapture={handlePlayPauseClick}
              onDragOver={handlePlayPauseClick}
              onDrop={handlePlayPauseClick}
              onAnimationIterationCapture={handlePlayPauseClick}
              onProgress={handlePlayPauseClick}
              onProgressCapture={handlePlayPauseClick} >
              <source src={music[0]} type='audio/mpeg' />
            </audio>

          </div>


        </div>
      </div>



    </div>
  );
};

export default ProjectDetail;