export const APP_ROUTES = {
    HOME: "/",
    
    ABOUT: "/about",

    // Mobile
    WORKS_MOBILE: "/works",
    
    // Works
    THEYBLOSSOM: "/they-blossom-at-night",
    GEMS: "/gems",
    CARNYX: "/carnyx",
    CARNYX_PERFORMANCE: "/carnyx-performance",
  };